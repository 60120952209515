import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CarListings = () => {
  const navigate = useNavigate();
  const [featuredListing, setFeaturedListing] = useState(null);
  const [normalListings, setNormalListings] = useState([]);

  useEffect(() => {
    // Przykładowe ogłoszenia (łącznie 11)
    const exampleListings = [
      {
        id: 1,
        title: 'BMW X5 2021',
        description:
          'Luksusowy SUV w idealnym stanie. Pierwszy właściciel, serwisowany w ASO.',
        price: '120,000 PLN',
        imageUrl: '/images/automobile-1834278_640.jpg',
        specs: {
          year: '2021',
          fuel: 'Diesel',
          mileage: '45,000 km',
          power: '286 KM',
        },
      },
      {
        id: 2,
        title: 'Audi A4 2020',
        description:
          'Premium sedan z napędem quattro. Stan idealny, pełna historia serwisowa.',
        price: '95,000 PLN',
        imageUrl: '/images/dodge-challenger-8214392_640.jpg',
        specs: {
          year: '2020',
          fuel: 'Benzyna',
          mileage: '62,000 km',
          power: '245 KM',
        },
      },
      {
        id: 3,
        title: 'Mercedes C200 2019',
        description:
          'Elegancki sedan w perfekcyjnym stanie. Bogate wyposażenie, jeden właściciel.',
        price: '85,000 PLN',
        imageUrl: '/images/car-1880381_640.jpg',
        specs: {
          year: '2019',
          fuel: 'Benzyna',
          mileage: '78,000 km',
          power: '184 KM',
        },
      },
      {
        id: 4,
        title: 'Volkswagen Golf 2018',
        description:
          'Ekonomiczny hatchback z niskim przebiegiem. Serwisowany w ASO.',
        price: '50,000 PLN',
        imageUrl: '/images/toyota-gr-yaris-6751752_640.jpg',
        specs: {
          year: '2018',
          fuel: 'Benzyna',
          mileage: '91,000 km',
          power: '150 KM',
        },
      },
      {
        id: 5,
        title: 'Ford Mustang 2017',
        description:
          'Legendarny muscle car z silnikiem V8. Stan kolekcjonerski, regularnie serwisowany.',
        price: '150,000 PLN',
        imageUrl: '/images/car-1880381_640.jpg',
        specs: {
          year: '2017',
          fuel: 'Benzyna',
          mileage: '55,000 km',
          power: '450 KM',
        },
      },
      {
        id: 6,
        title: 'Toyota Corolla 2021',
        description:
          'Niezawodny sedan hybrydowy. Niskie spalanie, idealny do miasta.',
        price: '65,000 PLN',
        imageUrl: '/images/automobile-1834278_640.jpg',
        specs: {
          year: '2021',
          fuel: 'Hybrid',
          mileage: '32,000 km',
          power: '122 KM',
        },
      },
      {
        id: 7,
        title: 'Kia Sportage 2019',
        description:
          'SUV z napędem 4x4. Przestronny i komfortowy, idealny dla rodziny.',
        price: '70,000 PLN',
        imageUrl: '/images/toyota-gr-yaris-6751752_640.jpg',
        specs: {
          year: '2019',
          fuel: 'Diesel',
          mileage: '88,000 km',
          power: '185 KM',
        },
      },
      {
        id: 8,
        title: 'Honda Civic 2020',
        description:
          'Sportowy kompakt z niskim przebiegiem. Gwarancja fabryczna.',
        price: '60,000 PLN',
        imageUrl: '/images/dodge-challenger-8214392_640.jpg',
        specs: {
          year: '2020',
          fuel: 'Benzyna',
          mileage: '45,000 km',
          power: '182 KM',
        },
      },
      {
        id: 9,
        title: 'Placeholder Car 1',
        description:
          'Tymczasowy opis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: '49,999 PLN',
        imageUrl: '/images/car-1880381_640.jpg',
        specs: {
          year: '2023',
          fuel: 'Benzyna',
          mileage: '10 km',
          power: '100 KM',
        },
      },
      {
        id: 10,
        title: 'Placeholder Car 2',
        description:
          'Drugi samochód testowy. Etiam at ante vitae enim varius bibendum.',
        price: '59,000 PLN',
        imageUrl: '/images/dodge-challenger-8214392_640.jpg',
        specs: {
          year: '2023',
          fuel: 'Diesel',
          mileage: '20 km',
          power: '110 KM',
        },
      },
      {
        id: 11,
        title: 'Placeholder Car 3',
        description:
          'Trzeci placeholder. Ut tincidunt nibh sit amet eros pharetra, nec rutrum metus accumsan.',
        price: '69,000 PLN',
        imageUrl: '/images/toyota-gr-yaris-6751752_640.jpg',
        specs: {
          year: '2023',
          fuel: 'Hybrid',
          mileage: '5 km',
          power: '90 KM',
        },
      },
    ];

    // Pierwsze ogłoszenie – wyróżnione
    const featured = exampleListings[0];
    // Pozostałe ogłoszenia
    const rest = exampleListings.slice(1);

    setFeaturedListing(featured);
    setNormalListings(rest);
  }, []);

  // Tabelka ze specyfikacją
  const renderSpecsTable = (specs) => (
    <div className="flex flex-col flex-1">
      <div className="grid grid-cols-4 bg-[#35530A] text-white text-xs rounded-t-[2px]">
        <div className="text-center p-1 border-r border-gray-200">Rok</div>
        <div className="text-center p-1 border-r border-gray-200">Paliwo</div>
        <div className="text-center p-1 border-r border-gray-200">Przebieg</div>
        <div className="text-center p-1">Moc</div>
      </div>
      <div className="grid grid-cols-4 bg-white text-xs flex-1 rounded-b-[2px]">
        <div className="text-center p-1 border-r border-gray-200 flex items-center justify-center">
          {specs.year}
        </div>
        <div className="text-center p-1 border-r border-gray-200 flex items-center justify-center">
          {specs.fuel}
        </div>
        <div className="text-center p-1 border-r border-gray-200 flex items-center justify-center">
          {specs.mileage}
        </div>
        <div className="text-center p-1 flex items-center justify-center">
          {specs.power}
        </div>
      </div>
    </div>
  );

  // Standardowa karta ogłoszenia
  const renderListingCard = (listing) => (
    <div className="bg-[#FFFFFF] shadow-sm hover:shadow-md transition-shadow duration-300 flex flex-col rounded-[2px] border border-gray-200 h-full">
      <div className="h-48 w-full rounded-t-[2px] relative">
        <img
          src={listing.imageUrl}
          alt={listing.title}
          className="w-full h-full object-cover rounded-t-[2px]"
        />
      </div>

      <div className="text-center py-2 bg-gradient-to-b from-[#E8E8E8] to-[#FEFEFE]">
        <h3 className="text-lg font-bold text-gray-800 px-2">{listing.title}</h3>
        <div className="h-0.5 bg-[#35530A] mx-auto mt-1.5 w-[70%] rounded-[2px]" />
      </div>

      <div className="p-3 flex flex-col flex-1">
        <p className="text-sm text-gray-600 text-center leading-normal mb-4 whitespace-pre-line">
          {listing.description}
        </p>

        {renderSpecsTable(listing.specs)}

        <div className="mt-auto">
          <div className="py-2.5 flex items-center justify-center bg-[#35530A] rounded-[2px]">
            <span className="font-bold text-white text-lg">{listing.price}</span>
          </div>
          <button
            onClick={() => navigate(`/listing/${listing.id}`)}
            className="w-full bg-[#35530A] text-white py-2.5 mt-1
                       rounded-[2px] hover:bg-[#2D4A06] transition-colors
                       focus:outline-none focus:ring-2 focus:ring-[#35530A] focus:ring-offset-1"
          >
            Pokaż ogłoszenie
          </button>
        </div>
      </div>
    </div>
  );

  // Karta wyróżnionego ogłoszenia (większa), pasek w rogu zdjęcia
  const renderFeaturedListing = (listing) => (
    <div className="bg-[#FFFFFF] shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col rounded-[2px] border border-gray-200 h-full">
      <div className="relative h-48 w-full rounded-t-[2px] overflow-hidden">
        <img
          src={listing.imageUrl}
          alt={listing.title}
          className="w-full h-full object-cover rounded-t-[2px]"
        />

        {/* Pasek "WYRÓŻNIONE" przez róg zdjęcia */}
        <div
          className="
            absolute
            z-10
            text-white
            font-bold
            text-sm
            px-4
            py-1.5
            bg-[#35530A]
            text-center
          "
          style={{
            width: '200px',
            transform: 'rotate(-45deg)',
            top: '25px',
            left: '-50px',
          }}
        >
          WYRÓŻNIONE
        </div>
      </div>

      <div className="text-center py-2 bg-gradient-to-b from-[#E8E8E8] to-[#FEFEFE] rounded-b-[2px]">
        <h2 className="text-lg font-bold text-gray-800 px-2">{listing.title}</h2>
        <div className="h-0.5 bg-[#35530A] mx-auto mt-1.5 w-[70%] rounded-[2px]" />
      </div>

      <div className="p-3 flex flex-col flex-1">
        <p className="text-sm text-gray-600 text-center leading-normal mb-4 whitespace-pre-line">
          {listing.description}
        </p>
        {renderSpecsTable(listing.specs)}

        <div className="mt-auto">
          <div className="py-2.5 flex items-center justify-center bg-[#35530A] rounded-[2px]">
            <span className="font-bold text-white text-lg">{listing.price}</span>
          </div>
          <button
            onClick={() => navigate(`/listing/${listing.id}`)}
            className="w-full bg-[#35530A] text-white py-2.5 mt-1
                       rounded-[2px] hover:bg-[#2D4A06] transition-colors
                       focus:outline-none focus:ring-2 focus:ring-[#35530A] focus:ring-offset-1"
          >
            Pokaż ogłoszenie
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#FCFCFC]">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-4xl font-bold text-center text-[#35530A] mb-10">
          OGŁOSZENIA
          <div className="w-24 h-0.5 bg-[#35530A] mx-auto mt-2 rounded-[2px]" />
        </h2>

        <div className="bg-[#FFFFFF] p-6 sm:p-8 rounded-[2px] shadow-md">
          {featuredListing && (
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 mb-6">
              {/* Wyróżnione ogłoszenie: col-span-1 (mobile), col-span-2 (≥640px) */}
              <div className="col-span-1 sm:col-span-2">
                {renderFeaturedListing(featuredListing)}
              </div>

              {/* Obok wyróżnionego w tym samym rzędzie: 2 normalne ogłoszenia */}
              {normalListings.slice(0, 2).map((listing) => (
                <div key={listing.id} className="col-span-1 sm:col-span-1">
                  {renderListingCard(listing)}
                </div>
              ))}
            </div>
          )}

          
          {/* Pozostałe ogłoszenia (od 3. dalej) */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {normalListings.slice(2).map((listing) => (
              <div key={listing.id}>{renderListingCard(listing)}</div>
            ))}
          </div>

          {/* Przycisk do całej listy */}
          <div className="text-center mt-10">
            <button
              onClick={() => navigate('/listings')}
              className="bg-[#35530A] text-white py-3 px-8 rounded-[2px]
                       text-lg hover:bg-[#2D4A06] transition-colors
                       focus:outline-none focus:ring-2 focus:ring-[#35530A]"
            >
              Przejdź do listy ogłoszeń
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarListings;