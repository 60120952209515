import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const carData = {
  Audi: ['A1','A3','A4','A5','A6','A7','A8','Q3','Q5','Q7','Q8','TT','R8'],
  BMW: [
    'Seria 1','Seria 2','Seria 3','Seria 4','Seria 5','Seria 6','Seria 7','Seria 8',
    'X1','X2','X3','X4','X5','X6','X7'
  ],
  Mercedes: [
    'Klasa A','Klasa B','Klasa C','Klasa E','Klasa S','CLA','CLS','GLA','GLB',
    'GLC','GLE','GLS'
  ],
  Volkswagen: [
    'Golf','Polo','Passat','Arteon','T-Roc','T-Cross','Tiguan','Touareg',
    'ID.3','ID.4'
  ],
  Toyota: [
    'Yaris','Corolla','Camry','RAV4','C-HR','Highlander','Land Cruiser','Supra','Prius'
  ],
  Ford: ['Fiesta','Focus','Mondeo','Kuga','Puma','Edge','Mustang','Explorer'],
  Opel: ['Corsa','Astra','Insignia','Crossland','Grandland','Mokka'],
  Hyundai: ['i20','i30','i40','Kona','Tucson','Santa Fe','IONIQ','NEXO'],
  Kia: ['Picanto','Rio','Ceed','Proceed','Stinger','XCeed','Sportage','Sorento'],
  Škoda: ['Fabia','Scala','Octavia','Superb','Kamiq','Karoq','Kodiaq','Enyaq'],
};

const bodyTypes = [
  'Sedan','Hatchback','SUV','Kombi','Coupé','Cabrio','VAN','Pickup'
];

const advancedOptions = {
  damageStatus: ['Brak uszkodzeń','Lekko uszkodzony','Poważnie uszkodzony'],
  country: ['Polska','Niemcy','Francja','Włochy'],
  fuelType: ['Benzyna','Diesel','Elektryczny','Hybrydowy'],
  driveType: ['Przedni','Tylny','4x4'],
};

export default function SearchForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    make: '',
    model: '',
    priceFrom: '',
    priceTo: '',
    yearFrom: '',
    yearTo: '',
    bodyType: '',
    damageStatus: '',
    country: '',
    fuelType: '',
    driveType: '',
    mileageFrom: '',
    mileageTo: '',
    location: '',
  });

  const [availableModels, setAvailableModels] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [matchingResults, setMatchingResults] = useState(1834);

  const calculateMatchingResults = (currentFormData) => {
    let baseCount = 1834;
    if (currentFormData.make) baseCount = Math.floor(baseCount * 0.7);
    if (currentFormData.model) baseCount = Math.floor(baseCount * 0.5);
    if (currentFormData.priceFrom) baseCount = Math.floor(baseCount * 0.8);
    if (currentFormData.priceTo) baseCount = Math.floor(baseCount * 0.8);
    if (currentFormData.yearFrom) baseCount = Math.floor(baseCount * 0.9);
    if (currentFormData.yearTo) baseCount = Math.floor(baseCount * 0.9);
    if (currentFormData.bodyType) baseCount = Math.floor(baseCount * 0.7);
    if (currentFormData.damageStatus) baseCount = Math.floor(baseCount * 0.8);
    if (currentFormData.fuelType) baseCount = Math.floor(baseCount * 0.6);
    if (currentFormData.driveType) baseCount = Math.floor(baseCount * 0.7);
    if (currentFormData.mileageFrom) baseCount = Math.floor(baseCount * 0.8);
    if (currentFormData.mileageTo) baseCount = Math.floor(baseCount * 0.8);
    if (currentFormData.location) baseCount = Math.floor(baseCount * 0.5);
    return baseCount;
  };

  useEffect(() => {
    const newCount = calculateMatchingResults(formData);
    setMatchingResults(newCount);
  }, [formData]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - 1989 }, (_, i) => currentYear - i);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (['priceFrom','priceTo','mileageFrom','mileageTo'].includes(name)) {
      if (Number(value) < 0) value = 0;
    }

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'make') {
      setAvailableModels(carData[value] || []);
      setFormData((prev) => ({ ...prev, model: '' }));
    }
  };

  const handleSearch = () => {
    console.log('Parametry wyszukiwania:', formData);
    navigate('/listings');
  };

  return (
    <section className="py-12 px-4">
      <div className="max-w-5xl mx-auto">
        <div className="border border-[#35530A] rounded-[2px] p-8 shadow-md">
          {/* Nagłówek */}
          

          {/* Podstawowe filtry */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {/* Marka */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Marka pojazdu
              </label>
              <select
                name="make"
                value={formData.make}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              >
                <option value="">Wybierz markę</option>
                {Object.keys(carData).map((make) => (
                  <option key={make} value={make}>
                    {make}
                  </option>
                ))}
              </select>
            </div>

            {/* Model */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Model pojazdu
              </label>
              <select
                name="model"
                value={formData.model}
                disabled={!formData.make}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A] disabled:bg-gray-100"
              >
                <option value="">Wybierz model</option>
                {availableModels.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>

            {/* Cena od */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Cena od (PLN)
              </label>
              <input
                type="number"
                name="priceFrom"
                placeholder="Cena od"
                min="0"
                value={formData.priceFrom}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              />
            </div>

            {/* Cena do */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Cena do (PLN)
              </label>
              <input
                type="number"
                name="priceTo"
                placeholder="Cena do"
                min="0"
                value={formData.priceTo}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              />
            </div>

            {/* Rok od */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Rok produkcji od
              </label>
              <select
                name="yearFrom"
                value={formData.yearFrom}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              >
                <option value="">Wybierz rok</option>
                {generateYearOptions().map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </select>
            </div>

            {/* Rok do */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Rok produkcji do
              </label>
              <select
                name="yearTo"
                value={formData.yearTo}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              >
                <option value="">Wybierz rok</option>
                {generateYearOptions().map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </select>
            </div>

            {/* Typ nadwozia */}
            <div>
              <label className="block text-sm font-semibold text-[#35530A] mb-1">
                Typ nadwozia
              </label>
              <select
                name="bodyType"
                value={formData.bodyType}
                onChange={handleInputChange}
                className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
              >
                <option value="">Wybierz typ nadwozia</option>
                {bodyTypes.map((body) => (
                  <option key={body} value={body}>
                    {body}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Sekcja ZAAWANSOWANEGO wyszukiwania */}
          {showAdvanced && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-6">
              {/* Stan uszkodzeń */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Stan uszkodzeń
                </label>
                <select
                  name="damageStatus"
                  value={formData.damageStatus}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                >
                  <option value="">Wybierz stan uszkodzeń</option>
                  {advancedOptions.damageStatus.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
              </div>

              {/* Kraj pochodzenia */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Kraj pochodzenia
                </label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                >
                  <option value="">Wybierz kraj</option>
                  {advancedOptions.country.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>

              {/* Rodzaj paliwa */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Rodzaj paliwa
                </label>
                <select
                  name="fuelType"
                  value={formData.fuelType}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                >
                  <option value="">Wybierz rodzaj paliwa</option>
                  {advancedOptions.fuelType.map((fuel) => (
                    <option key={fuel} value={fuel}>
                      {fuel}
                    </option>
                  ))}
                </select>
              </div>

              {/* Przebieg od */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Przebieg od (km)
                </label>
                <input
                  type="number"
                  name="mileageFrom"
                  placeholder="Przebieg od"
                  min="0"
                  value={formData.mileageFrom}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                />
              </div>

              {/* Przebieg do */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Przebieg do (km)
                </label>
                <input
                  type="number"
                  name="mileageTo"
                  placeholder="Przebieg do"
                  min="0"
                  value={formData.mileageTo}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                />
              </div>

              {/* Lokalizacja */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Lokalizacja
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Wprowadź lokalizację"
                  value={formData.location}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                />
              </div>

              {/* Napęd */}
              <div>
                <label className="block text-sm font-semibold text-[#35530A] mb-1">
                  Napęd
                </label>
                <select
                  name="driveType"
                  value={formData.driveType}
                  onChange={handleInputChange}
                  className="w-full h-10 text-sm px-3 border border-[#35530A] rounded-[2px] focus:ring-[#35530A] focus:border-[#35530A]"
                >
                  <option value="">Wybierz rodzaj napędu</option>
                  {advancedOptions.driveType.map((drive) => (
                    <option key={drive} value={drive}>
                      {drive}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {/* PRZYCISKI NA DOLE */}
          <div className="relative mt-6 flex items-center w-full">
            {/* Przycisk Zaawansowane – na środku (absolutny) */}
            <button
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="
                border border-[#35530A] 
                rounded-[2px]
                px-4 
                py-2 
                text-sm 
                text-[#35530A] 
                font-semibold 
                uppercase 
                bg-white
                hover:bg-[#2D4A06] 
                hover:text-white
                transition-colors

                absolute
                left-1/2 
                -translate-x-1/2
              "
            >
              {showAdvanced ? 'Ukryj zaawansowane wyszukiwanie' : 'Zaawansowane wyszukiwanie'}
            </button>

            {/* Pokaż ogłoszenia – wyrównany do prawej (ml-auto) */}
            <button
              onClick={handleSearch}
              className="
                bg-[#35530A] 
                text-white 
                rounded-[2px] 
                px-6 
                py-2 
                text-sm 
                font-bold 
                uppercase
                hover:bg-[#2D4A06] 
                transition-colors
                flex 
                items-center 
                gap-2
                ml-auto
              "
            >
              <span>Pokaż ogłoszenia</span>
              <span className="text-xs bg-[#35530A] text-white px-2 py-0.5 rounded">
                ({matchingResults})
              </span>
            </button>
          </div>

        </div>
      </div>
    </section>
  );
}
