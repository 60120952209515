import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMapMarker, FaHeart, FaTh, FaThList } from 'react-icons/fa';
import CompactSearch from './CompactSearch';

function generateSampleListings() {
  const cityRegionPairs = [
    { city: 'Warszawa', region: 'Mazowieckie' },
    { city: 'Kraków', region: 'Małopolskie' },
    { city: 'Poznań', region: 'Wielkopolskie' },
    { city: 'Gdańsk', region: 'Pomorskie' },
    { city: 'Wrocław', region: 'Dolnośląskie' },
  ];

  const listings = [];
  for (let i = 1; i <= 60; i++) {
    const { city, region } = cityRegionPairs[i % cityRegionPairs.length];

    listings.push({
      id: i,
      title: `VW Golf 7 1.${i} TSI`,
      subtitle:
        '(dane z tabelek), (+ krótki opis sprzedającego + nagłówka, max 60 znaków)',
      price: (Math.floor(Math.random() * 50) + 10) * 1000,
      fuel: ['Benzyna', 'Diesel', 'Elektryczny', 'Hybrydowy'][i % 4],
      engineCapacity: `${1000 + Math.floor(Math.random() * 1500)} cm³`,
      power: `${50 + Math.floor(Math.random() * 150)} KM`,
      mileage: `${5000 + Math.floor(Math.random() * 200000)} km`,
      year: 1990 + Math.floor(Math.random() * 35),
      drive: ['Napęd przedni', 'Napęd tylny', '4x4'][i % 3],
      location: region,
      city: city,
      gearbox: ['Automatyczna', 'Manualna'][i % 2],
      sellerType: ['Prywatny', 'Firma'][i % 2],
      image:
        i % 2 === 0
          ? '/images/auto-788747_1280.jpg'
          : '/images/car-1880381_640.jpg',
      featured: i <= 5,
      condition: i % 2 === 0 ? 'used' : 'new',
    });
  }
  return listings;
}

function ListingsPage() {
  const [listings] = useState(() => generateSampleListings());
  const [searchOpen, setSearchOpen] = useState(false);
  const [sortType, setSortType] = useState('none');
  const [offerType, setOfferType] = useState('all');
  const [onlyFeatured, setOnlyFeatured] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [itemsToShow, setItemsToShow] = useState(30);
  const [favorites, setFavorites] = useState([]);
  const [favMessages, setFavMessages] = useState({});

  const navigate = useNavigate();

  // Parse liczb (cena, przebieg) z ewentualnych stringów
  const parseNum = (val) =>
    parseInt(String(val).replace(/\D/g, ''), 10) || 0;

  // -----------------------------
  // Sortowanie i filtrowanie
  // -----------------------------
  let sortedListings = [...listings];
  switch (sortType) {
    case 'price-asc':
      sortedListings.sort((a, b) => parseNum(a.price) - parseNum(b.price));
      break;
    case 'price-desc':
      sortedListings.sort((a, b) => parseNum(b.price) - parseNum(a.price));
      break;
    case 'year-asc':
      sortedListings.sort((a, b) => a.year - b.year);
      break;
    case 'year-desc':
      sortedListings.sort((a, b) => b.year - a.year);
      break;
    case 'mileage-asc':
      sortedListings.sort((a, b) => parseNum(a.mileage) - parseNum(b.mileage));
      break;
    case 'mileage-desc':
      sortedListings.sort((a, b) => parseNum(b.mileage) - parseNum(a.mileage));
      break;
    default:
      break;
  }

  if (offerType !== 'all') {
    sortedListings = sortedListings.filter(
      (item) => item.condition === offerType
    );
  }

  if (onlyFeatured) {
    sortedListings = sortedListings.filter((item) => item.featured);
  }

  const visibleListings = sortedListings.slice(0, itemsToShow);

  // -----------------------------
  // Funkcje "ulubione" i nawigacja
  // -----------------------------
  const toggleFavorite = (id) => {
    const isFav = favorites.includes(id);
    let msg;
    if (isFav) {
      setFavorites((prev) => prev.filter((x) => x !== id));
      msg = 'Usunięto z ulubionych';
    } else {
      setFavorites((prev) => [...prev, id]);
      msg = 'Dodano do ulubionych';
    }
    setFavMessages((prev) => ({ ...prev, [id]: msg }));
    setTimeout(() => {
      setFavMessages((prev) => ({ ...prev, [id]: null }));
    }, 2000);
  };

  const handleShowMore = () => setItemsToShow((prev) => prev + 30);

  const navigateToListing = (id) => {
    navigate(`/listing/${id}`);
  };

  // ----------------------
  //       GRID ITEM
  // ----------------------
  const GridItem = ({ listing }) => {
    const message = favMessages[listing.id];
    const isFav = favorites.includes(listing.id);

    return (
      <div
        onClick={() => navigateToListing(listing.id)}
        className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer 
                    hover:shadow-lg transition-all duration-300 relative 
                    ${listing.featured ? 'border-2 border-green-600' : 'border border-gray-200'}
                    h-auto`}
      >
        {/* Zdjęcie */}
        <div className="relative h-[180px]">
          <img
            src={listing.image}
            alt={listing.title}
            className="w-full h-full object-cover"
            loading="lazy"
          />
          {listing.featured && (
            <div className="absolute top-2 left-2 bg-green-600 text-white px-2 py-1 text-xs rounded z-10">
              WYRÓŻNIONE
            </div>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite(listing.id);
            }}
            className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100"
            title={isFav ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
          >
            <FaHeart
              className={`text-2xl ${isFav ? 'text-red-500' : 'text-gray-300'}`}
            />
          </button>
        </div>

        {/* Tytuł i opis */}
        <div className="p-2 text-base">
          <h3 className="text-lg font-semibold">{listing.title}</h3>
          <p className="text-gray-600">{listing.subtitle}</p>
        </div>

        {/* Separator poziomy */}
        <hr className="border-gray-300 my-1" />

        {/* Dane techniczne (3 kolumny, małe odstępy, gap-1) */}
        <div className="px-2 text-base text-gray-700">
          <div className="grid grid-cols-3 divide-x divide-gray-300">
            <div className="pr-2">
              <p className="flex gap-1">
                <span className="text-gray-500">Paliwo:</span>
                <span className="font-medium">{listing.fuel}</span>
              </p>
              <p className="flex gap-1 mt-1">
                <span className="text-gray-500">Przebieg:</span>
                <span className="font-medium">{listing.mileage}</span>
              </p>
            </div>
            <div className="px-2">
              <p className="flex gap-1">
                <span className="text-gray-500">Pojemność:</span>
                <span className="font-medium">{listing.engineCapacity}</span>
              </p>
              <p className="flex gap-1 mt-1">
                <span className="text-gray-500">Rok:</span>
                <span className="font-medium">{listing.year}</span>
              </p>
            </div>
            <div className="pl-2">
              <p className="flex gap-1">
                <span className="text-gray-500">Moc:</span>
                <span className="font-medium">{listing.power}</span>
              </p>
              <p className="flex gap-1 mt-1">
                <span className="text-gray-500">Napęd:</span>
                <span className="font-medium">{listing.drive}</span>
              </p>
            </div>
          </div>
        </div>

        {/* Separator poziomy (przed sekcją "Skrzynia / Sprzedawca") */}
        <hr className="border-gray-300 my-1" />

        {/* 
          Kontener, w którym "Skrzynia / Sprzedawca" jest WYŚRODKOWANE w pionie 
          dzięki h-12 (lub innym) i flex items-center. 
          Pozwala to uzyskać efekt „prawie na środku” między kreskami.
        */}
        <div className="px-2 text-base text-gray-700 flex items-center justify-start h-12 space-x-6">
          <div className="flex gap-1">
            <span className="text-gray-500">Skrzynia:</span>
            <span className="font-medium">{listing.gearbox}</span>
          </div>
          <div className="flex gap-1">
            <span className="text-gray-500">Sprzedawca:</span>
            <span className="font-medium">{listing.sellerType}</span>
          </div>
        </div>

        {/* Separator poziomy (pod "Skrzynia / Sprzedawca") */}
        <hr className="border-gray-300 my-1" />

        {/* Stopka: lokalizacja i cena */}
        <div className="px-2 pb-2">
          <div className="flex items-center justify-between text-base">
            <div className="flex items-center text-gray-700">
              <FaMapMarker className="text-pink-500 mr-1.5" />
              <span>
                {listing.city} ({listing.location})
              </span>
            </div>
            <div className="text-xl font-bold text-gray-900">
              {listing.price.toLocaleString('pl-PL')} zł
            </div>
          </div>
        </div>

        {/* Komunikat "Dodano/Usunięto z ulubionych" */}
        {message && (
          <div className="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded">
            {message}
          </div>
        )}
      </div>
    );
  };

  // ----------------------
  //       LIST ITEM
  // ----------------------
  // Jeśli chcesz, możesz też podobnie ustawić h-12 i flex items-center 
  // w widoku listowym. Tutaj pozostawiam przykład bardziej standardowy.
  const ListItem = ({ listing }) => {
    const message = favMessages[listing.id];
    const isFav = favorites.includes(listing.id);

    return (
      <div
        onClick={() => navigateToListing(listing.id)}
        className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer 
                    hover:shadow-lg transition-all duration-300 relative 
                    ${listing.featured ? 'border-2 border-green-600' : 'border border-gray-200'}`}
      >
        <div className="flex">
          {/* Lewy blok: zdjęcie */}
          <div className="w-[300px] h-[180px] relative">
            <img
              src={listing.image}
              alt={listing.title}
              className="w-full h-full object-cover"
              loading="lazy"
            />
            {listing.featured && (
              <div className="absolute top-2 left-2 bg-green-600 text-white px-2 py-1 text-xs rounded">
                WYRÓŻNIONE
              </div>
            )}
          </div>

          {/* Środkowy blok: tytuł, opis, parametry */}
          <div className="flex-grow p-2 flex flex-col text-base">
            {/* Tytuł + krótki opis */}
            <div>
              <h3 className="text-lg font-semibold">{listing.title}</h3>
              <p className="text-gray-600">{listing.subtitle}</p>
            </div>

            {/* Separator poziomy */}
            <hr className="border-gray-300 my-1" />

            {/* Dane techniczne (3 kolumny) */}
            <div className="grid grid-cols-3 divide-x divide-gray-300 text-gray-700">
              <div className="pr-2">
                <p className="flex gap-1">
                  <span className="text-gray-500">Paliwo:</span>
                  <span className="font-medium">{listing.fuel}</span>
                </p>
                <p className="flex gap-1 mt-1">
                  <span className="text-gray-500">Przebieg:</span>
                  <span className="font-medium">{listing.mileage}</span>
                </p>
              </div>
              <div className="px-2">
                <p className="flex gap-1">
                  <span className="text-gray-500">Pojemność:</span>
                  <span className="font-medium">{listing.engineCapacity}</span>
                </p>
                <p className="flex gap-1 mt-1">
                  <span className="text-gray-500">Rok:</span>
                  <span className="font-medium">{listing.year}</span>
                </p>
              </div>
              <div className="pl-2">
                <p className="flex gap-1">
                  <span className="text-gray-500">Moc:</span>
                  <span className="font-medium">{listing.power}</span>
                </p>
                <p className="flex gap-1 mt-1">
                  <span className="text-gray-500">Napęd:</span>
                  <span className="font-medium">{listing.drive}</span>
                </p>
              </div>
            </div>

            {/* Separator poziomy (przed "Skrzynia / Sprzedawca") */}
            <hr className="border-gray-300 my-1" />

            {/* Sekcja skrzynia / sprzedawca – tu zostawiam standardowo */}
            <div className="text-gray-700 flex gap-6">
              <div className="flex gap-1">
                <span className="text-gray-500">Skrzynia:</span>
                <span className="font-medium">{listing.gearbox}</span>
              </div>
              <div className="flex gap-1">
                <span className="text-gray-500">Sprzedawca:</span>
                <span className="font-medium">{listing.sellerType}</span>
              </div>
            </div>

            {/* Wypełniacz – by cena i lokalizacja z prawej były „wyżej” */}
            <div className="mt-auto" />
          </div>

          {/* Prawy blok: lokalizacja i cena */}
          <div className="w-[200px] border-l border-gray-200 flex flex-col items-center justify-center p-2">
            {/* Lokalizacja */}
            <div className="flex items-center justify-center text-gray-700 mb-1">
              <FaMapMarker className="text-pink-500 mr-1" />
              <span className="text-sm">
                {listing.city} ({listing.location})
              </span>
            </div>

            {/* Separator poziomy */}
            <hr className="border-gray-300 w-full mb-1" />

            {/* Cena */}
            <div className="text-xl font-bold text-gray-900 text-center">
              {listing.price.toLocaleString('pl-PL')} zł
            </div>
          </div>

          {/* Kolumna na serduszko */}
          <div className="w-16 border-l border-gray-200 flex items-center justify-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleFavorite(listing.id);
              }}
              className="p-2 hover:scale-110 transition-transform duration-200"
            >
              <FaHeart
                className={`text-2xl ${isFav ? 'text-red-500' : 'text-gray-300'}`}
              />
            </button>
          </div>
        </div>

        {/* Komunikat "Dodano/Usunięto z ulubionych" */}
        {message && (
          <div className="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded">
            {message}
          </div>
        )}
      </div>
    );
  };

  // ----------------------
  //   STRONA GŁÓWNA
  // ----------------------
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Przycisk otwierający / zamykający wyszukiwarkę */}
        <div className="flex justify-center mb-6">
          <button
            onClick={() => setSearchOpen(!searchOpen)}
            className="text-white px-6 py-2.5 rounded shadow-md hover:shadow-lg transition-all duration-300"
            style={{
              backgroundColor: '#35530A',
              transition: 'all 0.2s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#44671A';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#35530A';
            }}
          >
            {searchOpen ? 'Ukryj wyszukiwarkę' : 'Pokaż wyszukiwarkę'}
          </button>
        </div>

        {/* Wyszukiwarka */}
        {searchOpen && <CompactSearch />}

        {/* Panel ustawień (sortowanie, filtry, przełącznik widoku) */}
        <div className="mt-4 flex flex-col sm:flex-row items-center justify-between border bg-white p-4 rounded-lg shadow-sm space-y-2 sm:space-y-0">
          <div className="flex space-x-3 items-center">
            {/* Sortowanie */}
            <select
              className="border border-green-600 p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
              value={sortType}
              onChange={(e) => setSortType(e.target.value)}
            >
              <option value="none">Brak sortowania</option>
              <option value="price-asc">Cena: rosnąco</option>
              <option value="price-desc">Cena: malejąco</option>
              <option value="year-asc">Rok: rosnąco</option>
              <option value="year-desc">Rok: malejąco</option>
              <option value="mileage-asc">Przebieg: rosnąco</option>
              <option value="mileage-desc">Przebieg: malejąco</option>
            </select>

            {/* Rodzaj oferty (nowe / używane) */}
            <select
              className="border border-green-600 p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
              value={offerType}
              onChange={(e) => setOfferType(e.target.value)}
            >
              <option value="all">Wszystkie</option>
              <option value="used">Używane</option>
              <option value="new">Nowe</option>
            </select>

            {/* Tylko wyróżnione */}
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={onlyFeatured}
                onChange={() => setOnlyFeatured((prev) => !prev)}
                className="w-4 h-4 rounded border-green-600 text-green-600 focus:ring-green-600"
              />
              <span>Tylko wyróżnione</span>
            </label>
          </div>

          {/* Przełącznik widoku (list / grid) */}
          <div className="flex space-x-2">
            <button
              onClick={() => setViewMode('list')}
              className={`p-2.5 border rounded-lg transition-all duration-200 ${
                viewMode === 'list'
                  ? 'bg-green-600 text-white border-green-600'
                  : 'border-gray-300 text-gray-600 hover:border-green-600 hover:text-green-600'
              }`}
            >
              <FaThList size={20} />
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2.5 border rounded-lg transition-all duration-200 ${
                viewMode === 'grid'
                  ? 'bg-green-600 text-white border-green-600'
                  : 'border-gray-300 text-gray-600 hover:border-green-600 hover:text-green-600'
              }`}
            >
              <FaTh size={20} />
            </button>
          </div>
        </div>

        {/* Lista lub kafelki */}
        <div
          className={
            viewMode === 'grid'
              ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6'
              : 'space-y-4 mt-6'
          }
        >
          {visibleListings.map((listing) =>
            viewMode === 'grid' ? (
              <GridItem key={listing.id} listing={listing} />
            ) : (
              <ListItem key={listing.id} listing={listing} />
            )
          )}
        </div>

        {/* Przycisk "Pokaż więcej" */}
        {itemsToShow < sortedListings.length && (
          <div className="text-center mt-8">
            <button
              onClick={handleShowMore}
              className="px-8 py-3 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 bg-[#35530A] hover:bg-[#44671A]"
            >
              Pokaż więcej
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListingsPage;
