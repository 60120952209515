import React from 'react';
import { FiPlus, FiMail, FiBell, FiHeart } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const UserPanel = () => {
  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Powitanie */}
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
        <div className="flex items-center space-x-4">
          <div className="w-14 h-14 sm:w-16 sm:h-16 bg-green-100 rounded-full flex items-center justify-center">
            <span className="text-xl sm:text-2xl text-green-600 font-bold">
              JK
            </span>
          </div>
          <div>
            <h1 className="text-xl sm:text-2xl font-bold">Witaj, Jan!</h1>
            <p className="text-gray-500 text-sm sm:text-base">
              Ostatnie logowanie: dziś, 12:30
            </p>
          </div>
        </div>
      </div>

      {/* Szybkie akcje */}
      {/**
       * Na mobile -> 1 kolumna
       * Od md -> 2 kolumny
       * Od lg (≥1024px) -> 4 kolumny
       * Możesz zmienić jak wolisz np. sm:grid-cols-2, md:grid-cols-4 itp.
       */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Link
          to="/user/listings/add"
          className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-xl transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 sm:w-12 sm:h-12 bg-green-100 rounded-full flex items-center justify-center">
              <FiPlus className="text-lg sm:text-2xl text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-sm sm:text-base">
                Dodaj ogłoszenie
              </h3>
              <p className="text-xs sm:text-sm text-gray-500">
                Wystaw nowy pojazd
              </p>
            </div>
          </div>
        </Link>

        <Link
          to="/user/messages"
          className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-xl transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 sm:w-12 sm:h-12 bg-blue-100 rounded-full flex items-center justify-center">
              <FiMail className="text-lg sm:text-2xl text-blue-600" />
            </div>
            <div>
              <h3 className="font-bold text-sm sm:text-base">Wiadomości</h3>
              <p className="text-xs sm:text-sm text-gray-500">5 nieprzeczytanych</p>
            </div>
          </div>
        </Link>

        <Link
          to="/user/notifications"
          className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-xl transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 sm:w-12 sm:h-12 bg-yellow-100 rounded-full flex items-center justify-center">
              <FiBell className="text-lg sm:text-2xl text-yellow-600" />
            </div>
            <div>
              <h3 className="font-bold text-sm sm:text-base">Powiadomienia</h3>
              <p className="text-xs sm:text-sm text-gray-500">3 nowe</p>
            </div>
          </div>
        </Link>

        <Link
          to="/user/favorites"
          className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-xl transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 sm:w-12 sm:h-12 bg-red-100 rounded-full flex items-center justify-center">
              <FiHeart className="text-lg sm:text-2xl text-red-600" />
            </div>
            <div>
              <h3 className="font-bold text-sm sm:text-base">Ulubione</h3>
              <p className="text-xs sm:text-sm text-gray-500">2 zapisane</p>
            </div>
          </div>
        </Link>
      </div>

      {/* Podsumowanie */}
      {/**
       * Na mobile -> 1 kolumna
       * Od md -> 2 kolumny
       */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Aktywne ogłoszenia */}
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
          <h3 className="font-bold text-lg mb-4">Twoje ogłoszenia</h3>
          <div className="space-y-4">
            <div className="border-b pb-4">
              <div className="flex justify-between items-center">
                <span className="font-medium text-sm sm:text-base">
                  Aktywne ogłoszenia
                </span>
                <span className="text-xl font-bold">2</span>
              </div>
            </div>
            <div className="border-b pb-4">
              <div className="flex justify-between items-center">
                <span className="font-medium text-sm sm:text-base">Wyświetlenia</span>
                <span className="text-xl font-bold">150</span>
              </div>
            </div>
          </div>
          <Link
            to="/user/listings"
            className="mt-4 inline-block text-green-600 hover:text-green-700 text-sm sm:text-base"
          >
            Zobacz wszystkie →
          </Link>
        </div>

        {/* Ostatnia aktywność */}
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
          <h3 className="font-bold text-lg mb-4">Ostatnia aktywność</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-sm sm:text-base">
                  Nowa wiadomość od użytkownika
                </p>
                <p className="text-xs sm:text-sm text-gray-500">2 godz. temu</p>
              </div>
              <Link
                to="/user/messages"
                className="text-blue-600 hover:text-blue-700 text-sm sm:text-base"
              >
                Odpowiedz →
              </Link>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-sm sm:text-base">
                  Dodano nowe ogłoszenie
                </p>
                <p className="text-xs sm:text-sm text-gray-500">wczoraj</p>
              </div>
              <Link
                to="/user/listings"
                className="text-blue-600 hover:text-blue-700 text-sm sm:text-base"
              >
                Zobacz →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPanel;
