// UserDashboard.js
import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { FiUser, FiMail, FiBell, FiClock, FiBarChart2, FiList, FiHeart, FiSettings } from 'react-icons/fi';

// Importy komponentów
import Messages from './Messages';
import Notifications from './Notifications';
import Transactions from './Transactions';
import Stats from './Stats';
import UserListings from './UserListings';
import Favorites from './Favorites';
import Settings from './Settings';
import UserPanel from './UserPanel';

const UserDashboard = () => {
  const location = useLocation();
  
  const menuItems = [
    {
      title: 'Panel Główny',
      path: '/user',
      icon: <FiUser size={20} />,
    },
    {
      title: 'Wiadomości',
      path: '/user/messages',
      icon: <FiMail size={20} />,
      badge: 5
    },
    {
      title: 'Powiadomienia',
      path: '/user/notifications',
      icon: <FiBell size={20} />,
      badge: 3
    },
    {
      title: 'Historia Transakcji',
      path: '/user/transactions',
      icon: <FiClock size={20} />
    },
    {
      title: 'Statystyki',
      path: '/user/stats',
      icon: <FiBarChart2 size={20} />
    },
    {
      title: 'Moje Ogłoszenia',
      path: '/user/listings',
      icon: <FiList size={20} />
    },
    {
      title: 'Ulubione',
      path: '/user/favorites',
      icon: <FiHeart size={20} />
    },
    {
      title: 'Ustawienia',
      path: '/user/settings',
      icon: <FiSettings size={20} />
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="flex gap-6">
          {/* Sidebar */}
          <div className="w-64 bg-white rounded-lg shadow-lg h-fit">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-xl font-bold text-green-800">Panel Użytkownika</h2>
            </div>
            <nav className="p-4">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center justify-between mb-2 p-3 rounded-lg transition-colors
                    ${location.pathname === item.path 
                      ? 'bg-green-100 text-green-800' 
                      : 'text-gray-600 hover:bg-gray-50'}`}
                >
                  <div className="flex items-center gap-3">
                    {item.icon}
                    <span className="font-medium">{item.title}</span>
                  </div>
                  {item.badge && (
                    <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                      {item.badge}
                    </span>
                  )}
                </Link>
              ))}
            </nav>
          </div>

          {/* Content */}
          <div className="flex-1">
            <Routes>
              <Route index element={<UserPanel />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="stats" element={<Stats />} />
              <Route path="listings" element={<UserListings />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="settings" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;