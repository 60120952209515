import React, { useState } from 'react';
import { 
 FiMail, 
 FiSend, 
 FiArchive, 
 FiTrash2, 
 FiSearch, 
 FiX, 
 FiPaperclip, 
 FiMoreVertical, 
 FiStar, 
 FiArrowUp, 
 FiArrowDown,
 FiCornerUpLeft,
 FiFolder,
 FiShare2 as FiForward
} from 'react-icons/fi';

const Messages = () => {
 const [activeTab, setActiveTab] = useState('przychodzace');
 const [isComposeOpen, setIsComposeOpen] = useState(false);
 const [selectedMessage, setSelectedMessage] = useState(null);
 const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
 const [searchTerm, setSearchTerm] = useState('');
 const [selectedMessages, setSelectedMessages] = useState(new Set());

 // Stan dla nowej wiadomości
 const [newMessage, setNewMessage] = useState({
   to: '',
   subject: '',
   content: '',
   attachments: []
 });

 // Przykładowe dane wiadomości
 const [inboxMessages] = useState([
   {
     id: 1,
     from: 'janek123',
     to: 'me',
     subject: 'BMW M3 2019 - Pytanie o dostępność',
     date: '2024-01-15',
     content: 'Dzień dobry,\n\nJestem zainteresowany BMW M3. Czy jest możliwość obejrzenia auta w najbliższy weekend?\n\nPozdrawiam,\nJan',
     isRead: false,
     isStarred: false,
     folder: 'przychodzace',
     labels: ['important'],
     attachments: []
   },
   {
     id: 2,
     from: 'annaK',
     to: 'me',
     subject: 'Mercedes C63 AMG - Oferta zakupu',
     date: '2024-01-14',
     content: 'Witam,\n\nChciałabym złożyć ofertę zakupu Mercedesa.\n\nPozdrawiam,\nAnna',
     isRead: true,
     isStarred: true,
     folder: 'przychodzace',
     labels: [],
     attachments: ['oferta.pdf']
   }
 ]);

 const [sentMessages] = useState([
   {
     id: 3,
     from: 'me',
     to: 'marek90',
     subject: 'Re: Porsche 911 - Potwierdzenie',
     date: '2024-01-13',
     content: 'Potwierdzam termin oględzin...',
     isStarred: false,
     folder: 'wyslane',
     labels: [],
     attachments: []
   }
 ]);

 const [draftMessages] = useState([
   {
     id: 4,
     to: 'tomek@example.com',
     subject: 'Odpowiedź - Audi RS6',
     date: '2024-01-12',
     content: 'Wersja robocza odpowiedzi...',
     folder: 'robocze',
     labels: [],
     attachments: []
   }
 ]);

 const [trashedMessages] = useState([
   {
     id: 5,
     from: 'old@example.com',
     subject: 'Stara korespondencja',
     date: '2024-01-01',
     content: 'Archiwalna wiadomość...',
     folder: 'kosz',
     labels: [],
     attachments: []
   }
 ]);

 // Pobieranie aktywnych wiadomości
 const getActiveMessages = () => {
   let messages = [];
   switch (activeTab) {
     case 'przychodzace':
       messages = inboxMessages;
       break;
     case 'wyslane':
       messages = sentMessages;
       break;
     case 'robocze':
       messages = draftMessages;
       break;
     case 'kosz':
       messages = trashedMessages;
       break;
     default:
       messages = [];
   }

   // Sortowanie
   messages = sortMessages(messages);

   // Filtrowanie według wyszukiwania
   if (searchTerm) {
     messages = messages.filter(message => 
       message.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
       message.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
       (message.from && message.from.toLowerCase().includes(searchTerm.toLowerCase())) ||
       (message.to && message.to.toLowerCase().includes(searchTerm.toLowerCase()))
     );
   }

   return messages;
 };

 // Funkcje zarządzania wiadomościami
 const handleSort = (key) => {
   setSortConfig(prevConfig => ({
     key,
     direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
   }));
 };

 const sortMessages = (messages) => {
   return [...messages].sort((a, b) => {
     if (sortConfig.direction === 'asc') {
       return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
     }
     return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
   });
 };

 const handleSelectMessage = (messageId) => {
   const newSelected = new Set(selectedMessages);
   if (newSelected.has(messageId)) {
     newSelected.delete(messageId);
   } else {
     newSelected.add(messageId);
   }
   setSelectedMessages(newSelected);
 };

 const handleBulkAction = (action) => {
   // Implementacja akcji masowych
   console.log(`Wykonuję akcję ${action} dla wiadomości:`, selectedMessages);
 };

 // Komponent modalu nowej wiadomości
 const ComposeModal = () => (
   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
     <div className="bg-white rounded-lg w-full max-w-3xl h-[600px] flex flex-col">
       {/* Nagłówek modalu */}
       <div className="p-4 border-b border-gray-200 flex justify-between items-center">
         <h3 className="text-lg font-semibold">Nowa wiadomość</h3>
         <div className="flex items-center gap-2">
           <button 
             title="Zapisz jako szkic"
             className="p-2 hover:bg-gray-100 rounded"
           >
             <FiFolder className="text-gray-500" />
           </button>
           <button 
             onClick={() => setIsComposeOpen(false)}
             className="p-2 hover:bg-gray-100 rounded"
           >
             <FiX className="text-gray-500" />
           </button>
         </div>
       </div>

       {/* Formularz */}
       <div className="p-4 flex-1 flex flex-col">
         <div className="mb-4">
           <input
             type="text"
             placeholder="Do:"
             className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
             value={newMessage.to}
             onChange={(e) => setNewMessage({...newMessage, to: e.target.value})}
           />
         </div>
         <div className="mb-4">
           <input
             type="text"
             placeholder="Temat:"
             className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
             value={newMessage.subject}
             onChange={(e) => setNewMessage({...newMessage, subject: e.target.value})}
           />
         </div>
         <textarea
           placeholder="Treść wiadomości..."
           className="flex-1 w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500 resize-none"
           value={newMessage.content}
           onChange={(e) => setNewMessage({...newMessage, content: e.target.value})}
         />
       </div>

       {/* Przyciski akcji */}
       <div className="p-4 border-t border-gray-200 flex justify-between items-center">
         <div className="flex gap-2">
           <input
             type="file"
             id="attachment"
             className="hidden"
             onChange={(e) => {
               if (e.target.files?.[0]) {
                 setNewMessage({
                   ...newMessage,
                   attachments: [...newMessage.attachments, e.target.files[0].name]
                 });
               }
             }}
           />
           <label 
             htmlFor="attachment" 
             className="p-2 text-gray-500 hover:bg-gray-100 rounded cursor-pointer"
           >
             <FiPaperclip size={20} />
           </label>
         </div>
         <div className="flex gap-2">
           <button 
             onClick={() => setIsComposeOpen(false)}
             className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
           >
             Anuluj
           </button>
           <button className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 flex items-center gap-2">
             <FiSend size={20} />
             Wyślij
           </button>
         </div>
       </div>

       {/* Lista załączników */}
       {newMessage.attachments.length > 0 && (
         <div className="px-4 pb-4 flex gap-2">
           {newMessage.attachments.map((attachment, index) => (
             <div key={index} className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded">
               <span className="text-sm">{attachment}</span>
               <button 
                 onClick={() => {
                   setNewMessage({
                     ...newMessage,
                     attachments: newMessage.attachments.filter((_, i) => i !== index)
                   });
                 }}
                 className="text-gray-500 hover:text-gray-700"
               >
                 <FiX size={16} />
               </button>
             </div>
           ))}
         </div>
       )}
     </div>
   </div>
 );

 // Komponent widoku szczegółów wiadomości
 const MessageView = () => (
   <div className="h-full flex flex-col">
     <div className="p-4 border-b border-gray-200 flex justify-between items-center">
       <h3 className="text-xl font-semibold">{selectedMessage.subject}</h3>
       <div className="flex gap-2">
         <button 
           className="p-2 hover:bg-gray-100 rounded"
           onClick={() => {
             setNewMessage({
               to: selectedMessage.from,
               subject: `Re: ${selectedMessage.subject}`,
               content: `\n\n> ${selectedMessage.content}`,
               attachments: []
             });
             setIsComposeOpen(true);
           }}
         >
           <FiCornerUpLeft className="text-gray-500" />
         </button>
         <button 
           className="p-2 hover:bg-gray-100 rounded"
           onClick={() => {
             setNewMessage({
               to: '',
               subject: `Fwd: ${selectedMessage.subject}`,
               content: selectedMessage.content,
               attachments: [...selectedMessage.attachments]
             });
             setIsComposeOpen(true);
           }}
         >
           <FiForward className="text-gray-500" />
         </button>
         <button className="p-2 hover:bg-gray-100 rounded">
           <FiArchive className="text-gray-500" />
         </button>
         <button className="p-2 hover:bg-gray-100 rounded">
           <FiTrash2 className="text-gray-500" />
         </button>
         <button className="p-2 hover:bg-gray-100 rounded">
           <FiMoreVertical className="text-gray-500" />
         </button>
       </div>
     </div>

     <div className="p-4 border-b border-gray-200">
       <div className="flex justify-between items-start">
         <div>
           <div className="flex items-center gap-2">
             <p className="font-medium">{selectedMessage.from}</p>
             <button className="p-1 hover:bg-gray-100 rounded">
               <FiStar className={selectedMessage.isStarred ? "text-yellow-400" : "text-gray-400"} />
             </button>
           </div>
           <p className="text-sm text-gray-500">Do: {selectedMessage.to}</p>
         </div>
         <div className="text-right">
           <p className="text-sm text-gray-500">{selectedMessage.date}</p>
           {selectedMessage.labels?.map((label, index) => (
             <span 
               key={index}
               className="ml-2 px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs"
             >
               {label}
             </span>
           ))}
         </div>
       </div>
     </div>

     <div className="p-4 flex-1 whitespace-pre-wrap overflow-auto">
       {selectedMessage.content}
     </div>

     {selectedMessage.attachments?.length > 0 && (
       <div className="p-4 border-t border-gray-200">
         <h4 className="text-sm font-medium mb-2">Załączniki:</h4>
         <div className="flex gap-2">
           {selectedMessage.attachments.map((att, index) => (
             <div key={index} className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded">
               <FiPaperclip className="text-gray-500" />
               <span className="text-sm">{att}</span>
             </div>
           ))}
         </div>
       </div>
     )}
   </div>
 );

 return (
   <div className="bg-white rounded-lg shadow-lg h-[calc(100vh-200px)] flex flex-col">
     {/* Nagłówek */}
     <div className="p-4 border-b border-gray-200">
       <div className="flex justify-between items-center">
         <h2 className="text-2xl font-bold text-gray-800">WIADOMOŚCI</h2>
         <button 
           onClick={() => setIsComposeOpen(true)}
           className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded flex items-center gap-2"
         >
           <FiMail />
           NOWA WIADOMOŚĆ
         </button>
       </div>

       {/* Wyszukiwarka */}
       <div className="mt-4 relative">
         <input
           type="text"
           placeholder="Szukaj w wiadomościach..."
           className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
           value={searchTerm}
           onChange={(e) => setSearchTerm(e.target.value)}
         />
         <FiSearch className="absolute left-3 top-3 text-gray-400" />
         </div>
     </div>

     {/* Zakładki */}
     <div className="flex border-b border-gray-200">
       <button
         onClick={() => setActiveTab('przychodzace')}
         className={`flex-1 py-3 px-4 text-center font-medium ${
           activeTab === 'przychodzace'
             ? 'text-green-600 border-b-2 border-green-600'
             : 'text-gray-600 hover:text-green-600'
         }`}
       >
         Przychodzące
         {inboxMessages.filter(m => !m.isRead).length > 0 && (
           <span className="ml-2 bg-red-500 text-white px-2 py-1 rounded-full text-xs">
             {inboxMessages.filter(m => !m.isRead).length}
           </span>
         )}
       </button>
       <button
         onClick={() => setActiveTab('wyslane')}
         className={`flex-1 py-3 px-4 text-center font-medium ${
           activeTab === 'wyslane'
             ? 'text-green-600 border-b-2 border-green-600'
             : 'text-gray-600 hover:text-green-600'
         }`}
       >
         Wysłane
       </button>
       <button
         onClick={() => setActiveTab('robocze')}
         className={`flex-1 py-3 px-4 text-center font-medium ${
           activeTab === 'robocze'
             ? 'text-green-600 border-b-2 border-green-600'
             : 'text-gray-600 hover:text-green-600'
         }`}
       >
         Robocze
         {draftMessages.length > 0 && (
           <span className="ml-2 bg-gray-500 text-white px-2 py-1 rounded-full text-xs">
             {draftMessages.length}
           </span>
         )}
       </button>
       <button
         onClick={() => setActiveTab('kosz')}
         className={`flex-1 py-3 px-4 text-center font-medium ${
           activeTab === 'kosz'
             ? 'text-green-600 border-b-2 border-green-600'
             : 'text-gray-600 hover:text-green-600'
         }`}
       >
         Kosz
       </button>
     </div>

     {/* Główny kontener z wiadomościami */}
     <div className="flex-1 flex">
       {/* Lista wiadomości */}
       <div className={`${selectedMessage ? 'hidden md:block md:w-1/3' : 'w-full'} border-r border-gray-200`}>
         <div className="divide-y divide-gray-200 overflow-auto h-full">
           {getActiveMessages().map((message) => (
             <div
               key={message.id}
               onClick={() => setSelectedMessage(message)}
               className={`p-4 hover:bg-gray-50 cursor-pointer ${
                 !message.isRead ? 'bg-green-50' : ''
               } ${selectedMessage?.id === message.id ? 'bg-gray-100' : ''}`}
             >
               <div className="flex items-center gap-2">
                 <input
                   type="checkbox"
                   checked={selectedMessages.has(message.id)}
                   onChange={(e) => {
                     e.stopPropagation();
                     handleSelectMessage(message.id);
                   }}
                   className="rounded border-gray-300"
                 />
                 <button
                   onClick={(e) => {
                     e.stopPropagation();
                     // Toggle starred status
                   }}
                   className="p-1 hover:bg-gray-100 rounded"
                 >
                   <FiStar className={message.isStarred ? "text-yellow-400" : "text-gray-400"} />
                 </button>
                 <span className={`font-medium ${!message.isRead ? 'text-green-600' : ''}`}>
                   {activeTab === 'wyslane' ? message.to : message.from}
                 </span>
                 <span className="text-sm text-gray-500">{message.date}</span>
               </div>
               <div className="font-medium mt-1">{message.subject}</div>
               <div className="text-sm text-gray-600 mt-1 truncate">
                 {message.content}
               </div>
             </div>
           ))}
         </div>
       </div>

       {/* Widok wybranej wiadomości */}
       {selectedMessage && (
         <div className="flex-1">
           <MessageView />
         </div>
       )}
     </div>

     {/* Modal do komponowania nowej wiadomości */}
     {isComposeOpen && <ComposeModal />}
   </div>
 );
};

export default Messages;