import React, { useState } from 'react';

const Settings = () => {
  const [settings, setSettings] = useState({
    notifications: {
      email: true,
      sms: false,
      push: true
    },
    privacy: {
      showPhone: false,
      showEmail: true
    },
    theme: 'light'
  });

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">USTAWIENIA KONTA</h2>

      <div className="space-y-6">
        {/* Dane osobowe */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Dane osobowe</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input 
                type="email" 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                value="user@example.com"
                disabled
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Numer telefonu</label>
              <input 
                type="tel" 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                value="+48 123 456 789"
              />
            </div>
          </div>
        </div>

        {/* Powiadomienia */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Powiadomienia</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-medium">Powiadomienia email</h4>
                <p className="text-sm text-gray-500">Otrzymuj powiadomienia na email</p>
              </div>
              <button 
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  settings.notifications.email ? 'bg-green-600' : 'bg-gray-200'
                }`}
                onClick={() => setSettings({
                  ...settings,
                  notifications: {
                    ...settings.notifications,
                    email: !settings.notifications.email
                  }
                })}
              >
                <span className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                  settings.notifications.email ? 'translate-x-5' : 'translate-x-0'
                }`} />
              </button>
            </div>
            
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-medium">Powiadomienia SMS</h4>
                <p className="text-sm text-gray-500">Otrzymuj powiadomienia SMS</p>
              </div>
              <button 
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  settings.notifications.sms ? 'bg-green-600' : 'bg-gray-200'
                }`}
                onClick={() => setSettings({
                  ...settings,
                  notifications: {
                    ...settings.notifications,
                    sms: !settings.notifications.sms
                  }
                })}
              >
                <span className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                  settings.notifications.sms ? 'translate-x-5' : 'translate-x-0'
                }`} />
              </button>
            </div>
          </div>
        </div>

        {/* Prywatność */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Prywatność</h3>
          <div className="space-y-4">
            {/* Ustawienia prywatności */}
          </div>
        </div>

        {/* Akcje konta */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold text-red-600 mb-4">Niebezpieczna strefa</h3>
          <div className="space-y-4">
            <button className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
              Usuń konto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;