import React from 'react';

function Banner() {
  return (
    <div
      className="
        relative 
        w-full 
        bg-gray-300 
        min-h-[250px] 
        max-h-[400px] 
        sm:min-h-[300px] 
        md:min-h-[400px] 
        lg:min-h-[500px]
        overflow-hidden
      "
    >
      {/* Szary placeholder zamiast docelowego obrazu 
          (możesz zamienić to tło na background-image przez Tailwind lub styl inline) */}
      <div className="absolute inset-0 bg-gray-400/30" />

      {/* Opcjonalny gradient przy dolnej krawędzi */}
      <div className="absolute bottom-0 left-0 right-0 h-20 sm:h-28 bg-gradient-to-t from-black/50 to-transparent"></div>

      {/* Warstwa z tekstem (wyśrodkowanie + responsywna typografia) */}
      <div
        className="
          relative 
          z-10 
          h-full 
          flex 
          flex-col 
          items-center 
          justify-center 
          text-white 
          font-semibold 
          text-center
        "
      >
        <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl">
          TU BĘDZIE HERO-IMAGE W FINALNEJ WERSJI
        </p>
        <p className="text-xs sm:text-sm md:text-base mt-2">
          250×400 px
        </p>
      </div>
    </div>
  );
}

export default Banner;
