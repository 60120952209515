import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Register({ setIsLoggedIn }) {
  const [step, setStep] = useState(1); // 1: dane podstawowe, 2: weryfikacja telefonu, 3: weryfikacja email
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    dob: '',
    termsAccepted: false,
    marketingAccepted: false,
    dataProcessingAccepted: false,
    phoneCode: '',
    emailCode: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationTimers, setVerificationTimers] = useState({
    phone: 0,
    email: 0
  });
  
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    
    if (step === 1) {
      if (!formData.name || formData.name.length < 2) {
        newErrors.name = 'Imię musi zawierać co najmniej 2 znaki.';
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Nieprawidłowy format email.';
      }

      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      if (!passwordRegex.test(formData.password)) {
        newErrors.password = 'Hasło musi mieć co najmniej 8 znaków, jedną wielką literę, jedną cyfrę i jeden znak specjalny.';
      }

      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Hasła nie są takie same.';
      }

      const phoneRegex = /^[0-9]{9,12}$/;
      if (!phoneRegex.test(formData.phone)) {
        newErrors.phone = 'Numer telefonu powinien zawierać 9-12 cyfr.';
      }

      if (!formData.dob) {
        newErrors.dob = 'Data urodzenia jest wymagana.';
      } else {
        const dobDate = new Date(formData.dob);
        const today = new Date();
        if (dobDate >= today) {
          newErrors.dob = 'Data urodzenia musi być z przeszłości.';
        }
      }

      if (!formData.termsAccepted || !formData.dataProcessingAccepted) {
        newErrors.agreements = 'Musisz zaakceptować regulamin i zgodę na przetwarzanie danych.';
      }
    }

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const startVerificationTimer = (type) => {
    setVerificationTimers(prev => ({ ...prev, [type]: 60 }));
    const timer = setInterval(() => {
      setVerificationTimers(prev => {
        const newTime = prev[type] - 1;
        if (newTime <= 0) {
          clearInterval(timer);
        }
        return { ...prev, [type]: Math.max(0, newTime) };
      });
    }, 1000);
  };

  const handleSendVerificationCode = async (type) => {
    try {
      await axios.post('http://localhost:5000/api/users/send-2fa', {
        [type]: formData[type]
      });
      startVerificationTimer(type);
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [type]: 'Błąd wysyłania kodu weryfikacyjnego'
      }));
    }
  };

  const handleVerifyCode = async (type) => {
    try {
      const response = await axios.post('http://localhost:5000/api/users/verify-2fa', {
        [type]: formData[type],
        code: formData[`${type}Code`]
      });

      if (response.data.verified) {
        if (type === 'phone') {
          setStep(3); // Przejdź do weryfikacji email
        } else if (type === 'email') {
          handleFinalSubmit(); // Zakończ rejestrację
        }
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [`${type}Code`]: 'Nieprawidłowy kod weryfikacyjny'
      }));
    }
  };

  const handleFinalSubmit = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/users/register', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        dob: formData.dob,
        termsAccepted: formData.termsAccepted,
        marketingAccepted: formData.marketingAccepted,
        dataProcessingAccepted: formData.dataProcessingAccepted
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setIsLoggedIn(true);
        navigate('/');
      }
    } catch (error) {
      setErrors({ general: 'Błąd podczas rejestracji. Spróbuj ponownie.' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    if (step === 1) {
      setStep(2); // Przejdź do weryfikacji telefonu
      await handleSendVerificationCode('phone');
    } else if (step === 2) {
      await handleVerifyCode('phone');
    } else if (step === 3) {
      await handleVerifyCode('email');
    }

    setIsSubmitting(false);
  };

  const renderError = (fieldName) => {
    if (errors[fieldName]) {
      return <p className="mt-1 text-sm text-red-600">{errors[fieldName]}</p>;
    }
    return null;
  };

  const renderVerificationStep = (type) => (
    <div className="space-y-6">
      <div className="text-center">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          Weryfikacja {type === 'phone' ? 'numeru telefonu' : 'adresu email'}
        </h3>
        <p className="text-gray-600 mb-4">
          Wprowadź kod weryfikacyjny wysłany na {type === 'phone' ? 'numer ' + formData.phone : 'adres ' + formData.email}
        </p>
      </div>

      <div className="space-y-4">
        <input
          type="text"
          name={`${type}Code`}
          value={formData[`${type}Code`]}
          onChange={handleInputChange}
          placeholder="Wprowadź kod"
          className="w-full px-4 py-3 text-center text-2xl tracking-widest border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
          maxLength="6"
        />
        {renderError(`${type}Code`)}

        <div className="flex justify-center gap-4">
          <button
            type="button"
            onClick={() => handleSendVerificationCode(type)}
            disabled={verificationTimers[type] > 0}
            className="text-[#35530A] hover:text-[#2D4A06] font-medium disabled:text-gray-400"
          >
            {verificationTimers[type] > 0 
              ? `Wyślij ponownie (${verificationTimers[type]}s)`
              : 'Wyślij ponownie kod'}
          </button>
        </div>
      </div>

      <div className="flex gap-4">
        <button
          type="button"
          onClick={() => setStep(step - 1)}
          className="w-1/3 border border-[#35530A] text-[#35530A] hover:bg-gray-50 font-bold py-3 px-4 rounded uppercase transition-colors"
        >
          Wstecz
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-2/3 bg-[#35530A] hover:bg-[#2D4A06] text-white font-bold py-3 px-4 rounded uppercase transition-colors disabled:opacity-50"
        >
          {isSubmitting ? 'Weryfikacja...' : 'Weryfikuj'}
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="w-full max-w-2xl p-8 bg-white rounded shadow-xl mx-4">
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-center text-[#35530A] uppercase">
            {step === 1 ? 'Zarejestruj się' : 
             step === 2 ? 'Weryfikacja telefonu' : 
             'Weryfikacja email'}
          </h2>
          {step > 1 && (
            <div className="mt-4 flex justify-center gap-2">
              <span className={`w-3 h-3 rounded-full ${step >= 1 ? 'bg-[#35530A]' : 'bg-gray-300'}`} />
              <span className={`w-3 h-3 rounded-full ${step >= 2 ? 'bg-[#35530A]' : 'bg-gray-300'}`} />
              <span className={`w-3 h-3 rounded-full ${step >= 3 ? 'bg-[#35530A]' : 'bg-gray-300'}`} />
            </div>
          )}
        </div>

        {errors.general && (
          <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
            <p className="text-sm">{errors.general}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {step === 1 && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                    Imię *
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                    required
                  />
                  {renderError('name')}
                </div>

                <div>
                  <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                    Data urodzenia *
                  </label>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                    required
                  />
                  {renderError('dob')}
                </div>
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                  required
                />
                {renderError('email')}
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                  Numer telefonu *
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                  required
                />
                {renderError('phone')}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                    Hasło *
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                    required
                  />
                  {renderError('password')}
                </div>

                <div>
                  <label className="block text-gray-700 text-sm font-semibold mb-2 uppercase">
                    Potwierdź hasło *
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:border-[#35530A] focus:ring-1 focus:ring-[#35530A]"
                    required
                  />
                  {renderError('confirmPassword')}
                </div>
              </div>

              <div className="space-y-4 border-t pt-6">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name="termsAccepted"
                      checked={formData.termsAccepted}
                      onChange={handleInputChange}
                      className="h-5 w-5 text-[#35530A] border-gray-300 rounded focus:ring-[#35530A]"
                      required
                    />
                  </div>
                  <label className="ml-3 text-sm text-gray-700">
                    * Oświadczam, że zapoznałem się z{' '}
                    <a href="/regulamin" className="text-[#35530A] hover:text-[#2D4A06] font-medium">
                      regulaminem
                    </a>
                    {' '}i akceptuję jego postanowienia
                  </label>
                </div>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name="dataProcessingAccepted"
                      checked={formData.dataProcessingAccepted}
                      onChange={handleInputChange}
                      className="h-5 w-5 text-[#35530A] border-gray-300 rounded focus:ring-[#35530A]"
                      required
                    />
                  </div>
                  <label className="ml-3 text-sm text-gray-700">
                    * Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z{' '}
                    <a href="/polityka-prywatnosci" className="text-[#35530A] hover:text-[#2D4A06] font-medium">
                      polityką prywatności
                    </a>
                  </label>
                </div>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name="marketingAccepted"
                      checked={formData.marketingAccepted}
                      onChange={handleInputChange}
                      className="h-5 w-5 text-[#35530A] border-gray-300 rounded focus:ring-[#35530A]"
                    />
                  </div>
                  <label className="ml-3 text-sm text-gray-700">
                    Wyrażam zgodę na otrzymywanie informacji marketingowych i handlowych drogą elektroniczną
                  </label>
                </div>
                {renderError('agreements')}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-[#35530A] hover:bg-[#2D4A06] text-white font-bold py-3 px-4 rounded uppercase transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Rejestracja...' : 'Dalej'}
              </button>
            </>
          )}

          {step === 2 && renderVerificationStep('phone')}
          {step === 3 && renderVerificationStep('email')}

          {step === 1 && (
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                Masz już konto?{' '}
                <a href="/login" className="text-[#35530A] hover:text-[#2D4A06] font-medium uppercase">
                  Zaloguj się
                </a>
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Register;