import React from 'react';

const Notifications = () => {
  const notifications = [
    {
      id: 1,
      title: 'Nowa wiadomość od użytkownika Janek...',
      date: '2024-01-06',
      isRead: false
    },
    {
      id: 2,
      title: 'Potwierdzenie wystawienia ogłoszenia...',
      date: '2024-01-05',
      isRead: true
    },
    {
      id: 3,
      title: 'Oferta specjalna: Pakiet wyróżnień...',
      date: '2024-01-04',
      isRead: true
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-6">POWIADOMIENIA</h2>
      
      <div className="space-y-4">
        {notifications.map(notification => (
          <div 
            key={notification.id}
            className={`p-4 rounded-lg border ${notification.isRead ? 'bg-white' : 'bg-green-50'}`}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className={`font-semibold ${notification.isRead ? 'text-gray-700' : 'text-green-700'}`}>
                  {notification.title}
                </h3>
                <p className="text-sm text-gray-500 mt-1">{notification.date}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;