import React from 'react';
import { FiEdit2, FiEye, FiTrash2, FiStar } from 'react-icons/fi';

const Listings = () => {
  const listings = [
    {
      id: 1,
      title: 'BMW M3 Competition',
      price: 450000,
      status: 'active',
      views: 123,
      publishDate: '2024-01-15',
      image: '/api/placeholder/200/150'
    },
    {
      id: 2,
      title: 'Audi RS6 Avant',
      price: 520000,
      status: 'pending',
      views: 45,
      publishDate: '2024-01-14',
      image: '/api/placeholder/200/150'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">MOJE OGŁOSZENIA</h2>
        <button className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg">
          + Dodaj nowe ogłoszenie
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {listings.map(listing => (
          <div key={listing.id} className="bg-white rounded-lg shadow p-4">
            <div className="flex space-x-4">
              <img 
                src={listing.image} 
                alt={listing.title} 
                className="w-48 h-36 object-cover rounded"
              />
              <div className="flex-1">
                <div className="flex justify-between">
                  <h3 className="text-xl font-semibold">{listing.title}</h3>
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    listing.status === 'active' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {listing.status}
                  </span>
                </div>
                <p className="text-2xl font-bold text-green-600 mt-2">
                  {listing.price.toLocaleString()} PLN
                </p>
                <div className="flex items-center mt-2 text-gray-500">
                  <FiEye className="mr-1" />
                  <span className="text-sm">{listing.views} wyświetleń</span>
                </div>
                <p className="text-sm text-gray-500 mt-1">
                  Dodano: {listing.publishDate}
                </p>
              </div>
              <div className="flex flex-col space-y-2">
                <button className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg">
                  <FiEdit2 size={20} />
                </button>
                <button className="p-2 text-yellow-600 hover:bg-yellow-50 rounded-lg">
                  <FiStar size={20} />
                </button>
                <button className="p-2 text-red-600 hover:bg-red-50 rounded-lg">
                  <FiTrash2 size={20} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Listings;