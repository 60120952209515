import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navigation = ({ isLoggedIn, handleLogout, setIsLoggedIn }) => {
  // Hamburger (mobile)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Dropdown „Mój Profil” (desktop)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  // Modal logowania
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // Przykładowe powiadomienia
  const [notifications] = useState({
    messages: 5,
    alerts: 3,
  });

  // Obsługa kliknięcia poza dropdownem (zamknij, jeśli otwarte)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isUserMenuOpen && !event.target.closest('.user-menu')) {
        setIsUserMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isUserMenuOpen]);

  // Otwieranie modala logowania
  const handleOpenLogin = () => {
    setIsLoginModalOpen(true);
    setIsMobileMenuOpen(false);
  };

  // Dropdown (desktop) – zawiera cały zestaw linków
  const UserMenu = () => (
    <div className="relative user-menu">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsUserMenuOpen(!isUserMenuOpen);
        }}
        className="
          px-4
          py-2
          font-bold
          uppercase
          rounded-[2px]
          hover:bg-[#2D4A06]
          transition-colors
          relative
          flex
          items-center
          gap-2
        "
      >
        Mój Profil
        {(notifications.messages + notifications.alerts > 0) && (
          <div
            className="
              absolute
              -top-2
              -right-2
              bg-red-500
              text-white
              text-xs
              rounded-full
              h-5
              w-5
              flex
              items-center
              justify-center
            "
          >
            {notifications.messages + notifications.alerts}
          </div>
        )}
      </button>

      {isUserMenuOpen && (
        <div
          className="
            absolute
            right-0
            mt-2
            w-64
            bg-white
            rounded-[2px]
            shadow-xl
            z-50
            border
            border-gray-200
            transform          // dodano
            translate-x-0      // dodano
          "
        >
          <div className="py-2">
            <Link
              to="/admin"
              className="block px-4 py-2 font-bold text-green-700 hover:bg-green-50 uppercase"
            >
              Panel Administratora
            </Link>

            <Link
              to="/user"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Mój Profil
            </Link>
            <Link
              to="/user/messages"
              className="
                flex
                items-center
                justify-between
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Wiadomości
              {notifications.messages > 0 && (
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  {notifications.messages}
                </span>
              )}
            </Link>
            <Link
              to="/user/notifications"
              className="
                flex
                items-center
                justify-between
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Powiadomienia
              {notifications.alerts > 0 && (
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  {notifications.alerts}
                </span>
              )}
            </Link>
            <Link
              to="/user/transactions"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Historia Transakcji
            </Link>
            <Link
              to="/user/stats"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Statystyki Konta
            </Link>
            <Link
              to="/user/listings"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Moje Ogłoszenia
            </Link>
            <Link
              to="/user/favorites"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Ulubione
            </Link>
            <Link
              to="/user/settings"
              className="
                block
                px-4
                py-2
                text-gray-800
                hover:bg-green-50
                uppercase
              "
            >
              Ustawienia konta
            </Link>

            <button
              onClick={handleLogout}
              className="
                w-full
                text-left
                px-4
                py-2
                text-red-600
                hover:bg-red-50
                uppercase
              "
            >
              Wyloguj się
            </button>
          </div>
        </div>
      )}
    </div>
  );

  // Modal logowania
  const LoginModal = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 px-2 sm:px-4">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-2xl max-w-md w-full relative">
        <button
          onClick={() => setIsLoginModalOpen(false)}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-900 text-2xl font-bold"
        >
          ×
        </button>
        <h2 className="text-xl sm:text-2xl font-bold uppercase mb-6 text-center text-[#35530A]">
          Zaloguj się
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsLoggedIn(true);
            localStorage.setItem('token', 'dummyToken');
            localStorage.setItem(
              'user',
              JSON.stringify({
                id: 1,
                name: 'Admin',
                email: 'admin@example.com',
                role: 'admin'
              })
            );
            setIsLoginModalOpen(false);
            window.location.reload();
          }}
          className="flex flex-col space-y-4"
        >
          <div>
            <label
              className="block text-sm font-medium mb-1 uppercase text-gray-700"
              htmlFor="email"
            >
              Email lub Numer Telefonu
            </label>
            <input
              type="text"
              id="email"
              className="
                w-full
                border
                border-gray-300
                rounded-[2px]
                p-2
                focus:outline-none 
                focus:border-[#35530A]
                focus:ring-1
                focus:ring-[#35530A]
              "
              placeholder="Podaj swój email lub telefon"
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1 uppercase text-gray-700"
              htmlFor="password"
            >
              Hasło
            </label>
            <input
              type="password"
              id="password"
              className="
                w-full
                border
                border-gray-300
                rounded-[2px]
                p-2
                focus:outline-none 
                focus:border-[#35530A]
                focus:ring-1
                focus:ring-[#35530A]
              "
              placeholder="Podaj hasło"
            />
          </div>
          <button
            type="submit"
            className="
              w-full
              bg-[#35530A]
              text-white
              py-3
              rounded-[2px]
              uppercase 
              text-base
              font-semibold
              hover:bg-[#2D4A06]
              transition-colors
            "
          >
            Zaloguj się
          </button>
        </form>
        <div className="mt-6 text-sm text-center">
          <a
            href="#reset"
            onClick={(e) => e.preventDefault()}
            className="text-[#35530A] hover:underline font-semibold uppercase"
          >
            Odzyskaj hasło
          </a>
          <br />
          <span className="uppercase text-gray-700">Nie masz konta?</span>{' '}
          <Link
            to="/register"
            className="text-[#35530A] font-semibold hover:underline uppercase"
            onClick={() => setIsLoginModalOpen(false)}
          >
            Zarejestruj się
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <header className="bg-[#35530A] text-white sticky top-0 z-50 shadow-md">
      <div
        className="
          max-w-screen-2xl
          mx-auto
          flex
          items-center
          justify-between
          px-4
          lg:px-8
          py-3
          text-sm
          sm:text-base
          md:text-lg
        "
      >
        {/* Logo */}
        <div className="flex items-center gap-3 min-w-[180px] sm:gap-4 md:min-w-[240px]">
          <div className="w-8 h-8 sm:w-10 sm:h-10 bg-white text-gray-800 flex items-center justify-center rounded-[2px] font-bold text-xs sm:text-base">
            LOGO
          </div>
          <h2 className="font-bold uppercase tracking-wide">
            <Link to="/">AutoSell.PL</Link>
          </h2>
        </div>

        {/* NAV Desktop */}
        <nav className="hidden md:flex flex-1 justify-center">
          <ul className="flex items-center gap-6 sm:gap-8 font-bold uppercase">
            <li>
              <Link
                to="/"
                className="hover:bg-[#2D4A06] px-3 py-2 rounded-[2px] transition-colors"
              >
                Strona główna
              </Link>
            </li>
            <li>
              <Link
                to="/listings"
                className="hover:bg-[#2D4A06] px-3 py-2 rounded-[2px] transition-colors"
              >
                Lista ogłoszeń
              </Link>
            </li>
            {!isLoggedIn ? (
              <li>
                <Link
                  to="/about-us"
                  className="hover:bg-[#2D4A06] px-3 py-2 rounded-[2px] transition-colors"
                >
                  Kontakt
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link
                    to="/favorites"
                    className="hover:bg-[#2D4A06] px-3 py-2 rounded-[2px] transition-colors"
                  >
                    Ulubione
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="hover:bg-[#2D4A06] px-3 py-2 rounded-[2px] transition-colors"
                  >
                    Kontakt
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        {/* Prawa strona: hamburger (mobile) + "Dodaj ogłoszenie" + Mój Profil (dropdown desktop) */}
        {/* ---------------------------------------------------------------------- */}
        {/* Tutaj wprowadzamy poprawki (ml-8, relative itp.) */}
        {/* ---------------------------------------------------------------------- */}
        <div className="flex items-center gap-5 sm:gap-8 md:gap-10 ml-8"> {/* dodano ml-8 */}
          {/* Hamburger - widoczny tylko < md */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden text-white"
            aria-label="Menu mobilne"
          >
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Dodaj ogłoszenie (tylko desktop) */}
          <Link
            to={isLoggedIn ? '/createlisting' : '#'}
            onClick={(e) => {
              if (!isLoggedIn) {
                e.preventDefault();
                setIsLoginModalOpen(true);
              }
            }}
            className="
              hidden
              md:inline-flex
              items-center
              bg-yellow-500
              px-4
              py-2
              rounded-[2px]
              shadow-md
              font-bold
              uppercase
              text-green-800
              hover:opacity-90
              transition-opacity
            "
          >
            Dodaj ogłoszenie
          </Link>

          {/* Desktop: Mój Profil (dropdown) albo Logowanie/Rejestracja */}
          {isLoggedIn ? (
            <div className="hidden md:block relative"> {/* dodano relative */}
              <UserMenu />
            </div>
          ) : (
            // -------------------------------------
            // ZMIENIONY KAWAŁEK - DODANO ml-8 TUTAJ
            // -------------------------------------
            <div className="hidden md:flex items-center gap-5 ml-8"> {/* dodano ml-8 */}
              <button
                onClick={() => setIsLoginModalOpen(true)}
                className="px-4 py-2 font-bold uppercase hover:bg-[#2D4A06] rounded-[2px] transition-colors border border-white/20"
              >
                Zaloguj się
              </button>
              <Link
                to="/register"
                className="px-4 py-2 font-bold uppercase hover:bg-[#2D4A06] rounded-[2px] transition-colors border border-white/20"
              >
                Zarejestruj się
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Menu mobilne (hamburger) - widoczne tylko < md */}
      {isMobileMenuOpen && (
        <nav className="md:hidden bg-[#35530A] border-t border-[#2D4A06]">
          <ul className="flex flex-col py-2">
            {/* Panel Administratora */}
            {isLoggedIn && (
              <li>
                <Link
                  to="/admin"
                  className="
                    block
                    px-6
                    py-2
                    font-bold
                    text-yellow-400
                    uppercase
                    hover:bg-[#2D4A06]
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Panel Administratora
                </Link>
              </li>
            )}

            {/* Mój Profil - bez dropdown na mobile, ale z chmurką */}
            {isLoggedIn && (
              <li className="relative">
                <Link
                  to="/user"
                  className="
                    block
                    px-6
                    py-2
                    font-bold
                    text-yellow-300
                    uppercase
                    hover:bg-[#2D4A06]
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Mój Profil
                  {(notifications.messages + notifications.alerts > 0) && (
                    <span
                      className="
                        absolute
                        right-5
                        top-2
                        bg-red-500
                        text-white
                        text-xs
                        rounded-full
                        h-5
                        w-5
                        flex
                        items-center
                        justify-center
                      "
                    >
                      {notifications.messages + notifications.alerts}
                    </span>
                  )}
                </Link>
              </li>
            )}

            {/* Dodaj ogłoszenie */}
            <li>
              {isLoggedIn ? (
                <Link
                  to="/createlisting"
                  className="
                    block
                    px-6
                    py-2
                    font-bold
                    text-yellow-200
                    uppercase
                    hover:bg-[#2D4A06]
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Dodaj ogłoszenie
                </Link>
              ) : (
                <button
                  onClick={handleOpenLogin}
                  className="
                    block
                    w-full
                    text-left
                    px-6
                    py-2
                    font-bold
                    text-yellow-200
                    uppercase
                    hover:bg-[#2D4A06]
                  "
                >
                  Dodaj ogłoszenie
                </button>
              )}
            </li>

            {/* Strona główna */}
            <li>
              <Link
                to="/"
                className="
                  block
                  px-6
                  py-2
                  hover:bg-[#2D4A06]
                  uppercase
                "
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Strona główna
              </Link>
            </li>

            {/* Lista ogłoszeń */}
            <li>
              <Link
                to="/listings"
                className="
                  block
                  px-6
                  py-2
                  hover:bg-[#2D4A06]
                  uppercase
                "
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Lista ogłoszeń
              </Link>
            </li>

            {/* Ulubione */}
            {isLoggedIn && (
              <li>
                <Link
                  to="/favorites"
                  className="
                    block
                    px-6
                    py-2
                    hover:bg-[#2D4A06]
                    uppercase
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Ulubione
                </Link>
              </li>
            )}

            {/* Kontakt */}
            {!isLoggedIn ? (
              <li>
                <Link
                  to="/about-us"
                  className="
                    block
                    px-6
                    py-2
                    hover:bg-[#2D4A06]
                    uppercase
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Kontakt
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to="/contact"
                  className="
                    block
                    px-6
                    py-2
                    hover:bg-[#2D4A06]
                    uppercase
                  "
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Kontakt
                </Link>
              </li>
            )}

            {/* Wyloguj się */}
            {isLoggedIn && (
              <li>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="
                    w-full
                    text-left
                    px-6
                    py-2
                    hover:bg-[#2D4A06]
                    uppercase
                  "
                >
                  Wyloguj się
                </button>
              </li>
            )}

            {/* Logowanie + Rejestracja (niezalogowany) */}
            {!isLoggedIn && (
              <>
                <li>
                  <button
                    onClick={handleOpenLogin}
                    className="
                      w-full
                      text-left
                      px-6
                      py-2
                      hover:bg-[#2D4A06]
                      uppercase
                    "
                  >
                    Zaloguj się
                  </button>
                </li>
                <li>
                  <Link
                    to="/register"
                    className="
                      block
                      px-6
                      py-2
                      hover:bg-[#2D4A06]
                      uppercase
                    "
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Zarejestruj się
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}

      {isLoginModalOpen && <LoginModal />}
    </header>
  );
};

export default Navigation;
