import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Banner from './components/Banner';
import SearchForm from './components/SearchForm';
import FeaturedListings from './components/FeaturedListings';
import AdBanner from './components/AdBanner';
import ListingsPage from './components/ListingsView/ListingsPage';
import CreateListing from './components/listings/CreateListing';
import AddListingView from './components/listings/AddListingView';
import Register from './components/Register';
import ListingDetails from './components/listings/ListingDetails';
import Contact from './components/Contact';
import Profile from './components/Profile';
import FavoritesPage from './components/FavoritesPage';
import { FavoritesProvider } from './FavoritesContext';
import ScrollToTop from './ScrollToTop';

// Komponenty admina
import AdminLayout from './components/admin/AdminLayout';
import AdminDashboard from './components/admin/dashboard/AdminDashboard';
import AdminUsers from './components/admin/users/AdminUsers';
import AdminListings from './components/admin/listings/AdminListings';
import AdminComments from './components/comments/admin/AdminComments';
import AdminDiscounts from './components/discounts/admin/AdminDiscounts';

// Komponenty profilu użytkownika
import UserDashboard from './components/profil/UserDashboard';
import Messages from './components/profil/Messages';
import Notifications from './components/profil/Notifications';
import Transactions from './components/profil/Transactions';
import Stats from './components/profil/Stats';
import UserListings from './components/profil/UserListings';
import UserFavorites from './components/profil/Favorites';
import Settings from './components/profil/Settings';

// Style dla mapy
import 'leaflet/dist/leaflet.css';

const App = () => {
 const [isLoggedIn, setIsLoggedIn] = useState(false);
 const [isAdmin, setIsAdmin] = useState(false);

 useEffect(() => {
   const checkAuthStatus = () => {
     const token = localStorage.getItem('token');
     const user = JSON.parse(localStorage.getItem('user') || '{}');
     setIsLoggedIn(!!token);
     setIsAdmin(user.role === 'admin');
   };

   checkAuthStatus();
 }, []);

 const handleLogout = () => {
   localStorage.removeItem('token');
   localStorage.removeItem('user');
   setIsLoggedIn(false);
   setIsAdmin(false);
 };

 const HomePageContent = () => (
   <>
     <Banner />
     <SearchForm />
     <FeaturedListings />
     <AdBanner />
   </>
 );

 return (
   <FavoritesProvider>
     <Router>
       <ScrollToTop />
       <div className="flex flex-col min-h-screen">
         <Navigation
           isLoggedIn={isLoggedIn}
           isAdmin={isAdmin}
           handleLogout={handleLogout}
           setIsLoggedIn={setIsLoggedIn}
         />

         <main className="flex-grow">
           <Routes>
             {/* Strona główna */}
             <Route path="/" element={<HomePageContent />} />

             {/* Ogłoszenia */}
             <Route path="/listings" element={<ListingsPage />} />
             <Route path="/listing/:id" element={<ListingDetails />} />
             <Route path="/createlisting" element={<CreateListing />} />
             <Route path="/addlistingview" element={<AddListingView />} />

             {/* Konto użytkownika */}
             <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />
             <Route path="/contact" element={<Contact />} />
             <Route path="/profile" element={<Profile />} />
             <Route path="/favorites" element={<FavoritesPage />} />

             {/* Panel Admina - chroniony routing */}
             {isAdmin && (
               <Route path="/admin" element={<AdminLayout />}>
                 <Route index element={<AdminDashboard />} />
                 <Route path="users" element={<AdminUsers />} />
                 <Route path="listings" element={<AdminListings />} />
                 <Route path="comments" element={<AdminComments />} />
                 <Route path="discounts" element={<AdminDiscounts />} />
               </Route>
             )}

             {/* Panel użytkownika */}
             <Route path="/user" element={<UserDashboard />}>
               <Route index element={<UserDashboard />} />
               <Route path="messages" element={<Messages />} />
               <Route path="notifications" element={<Notifications />} />
               <Route path="transactions" element={<Transactions />} />
               <Route path="stats" element={<Stats />} />
               <Route path="listings" element={<UserListings />} />
               <Route path="favorites" element={<UserFavorites />} />
               <Route path="settings" element={<Settings />} />
             </Route>
           </Routes>
         </main>

         <Footer />
       </div>
     </Router>
   </FavoritesProvider>
 );
};

export default App;